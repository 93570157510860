@import "../../scss/main.scss";

.input {
  position: relative;
  &__main {
    width: 100%;
    background-color: $grayE430;
    border-radius: 8px;
    padding: 15px 18px;
    @include text_small_regular;
    caret-color: $primary;
    color: #000;

    resize: none;
    height: 145px;
    &::placeholder {
      color: $grayLight;
    }
    border: 1px solid transparent;
    &.error {
      border-color: $red;
    }

    &.borderError {
      border-color: $red;
    }

    &:focus {
      outline: none;
      border-color: $primary;
      border-width: 2px;
    }
  }
  &:not(:last-child) {
    // margin-bottom: 18px;
  }
  span {
    display: block;
    color: $grayLight;
    margin-bottom: 10px;
    @include text_x_small_medium;
  }
  p {
    position: absolute;
    font-size: 11px;
    color: $red;
    line-height: 18px;
  }
}
