@import "../../scss/main.scss";

.wrapper {
  display: flex;
  flex-direction: row;
  gap: 15px;

  & .imageWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    border-radius: 8px;
    background-color: $grayE430;
    position: relative;
    & svg {
      fill: $grayDark;
      width: 26px;
      height: 26px;
    }

    & .image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
    & .checkIconWrapper {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $green;
      border-radius: 100px;
      position: absolute;
      right: -5px;
      top: -5px;

      & svg {
        width: 14px;
        height: 14px;
        fill: $white;
      }
    }
  }

  & .contentContainer {
    flex: 1;
    & .title {
      @include text_small_bold;
    }

    & .macroContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      & .macroItem {
        display: flex;
        flex-direction: column;

        & .macroValue {
          @include text_small_bold;
        }

        & .macroTitle {
          @include text_x_small_regular;
          color: $grayDark;
        }
      }
    }
  }
}
