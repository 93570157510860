@import "../../scss/main.scss";

.wrapper {
  position: relative;

  & span {
    display: block;
    color: $grayLight;
    margin-bottom: 10px;
    @include text_x_small_medium;
  }
  .selector {
    background-color: $grayE430;
    padding: 0 23px;
    min-height: 56px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    // min-width: 235px;
    cursor: pointer;
    transition: all 0.2s linear;

    &:hover {
      background: #e1e1e1;
    }

    & .titleContainer {
      flex: 1;
    }

    & .title {
      @include text_small_bold;
    }

    & .placeholder {
      @include text_small_bold;
      color: $grayDark;
    }

    & .description {
      @include text_small_bold;
      color: $grayLight;
    }
    & svg {
      transition: all 0.2s linear;
      stroke: $dark;
    }
  }

  & .activeSelector {
    & svg {
      transition: all 0.2s linear;
      transform: rotate(180deg);
    }
  }

  & .dataList {
    position: absolute;
    overflow: hidden;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    background: #f6f6f6;
    border-radius: 12px;
    margin-top: 2px;
    z-index: 10;
    max-height: 300px;
    overflow-y: scroll;

    &::-webkit-scrollbar-track {
    }

    &::-webkit-scrollbar {
      background-color: $white;
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $grayE4;
      border-radius: 100px;
    }

    & .itemlist {
      padding: 18px 23px;
      @include text_small_medium;
      cursor: pointer;
      transition: all 0.2s linear;

      &:not(:last-child) {
        border-bottom: 1px solid #e4e4e4;
      }

      &:hover {
        background-color: #ececec;
      }
    }
  }
}
