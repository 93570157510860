@import "../../scss/main.scss";

.overlay {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $overlay;
  overflow-y: auto;
}

.modal {
  max-height: 100vh;

  & .innerContainer {
    position: relative;
    width: 730px;
    margin-top: 50px;
    border-radius: 24px;
    background: $white;
    overflow: hidden;
    padding-bottom: 100px;

    & .imageBackground {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 334px;
      background: $grayE430;

      & svg {
        fill: $grayDark;
      }
    }

    & .contentContainer {
      padding: 25px 30px;

      & .dateSwitcher {
        display: flex;
        align-items: center;
        margin-top: 25px;
        margin-bottom: 20px;

        & .dateSwitcherLeftButton {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 36px;
          height: 36px;
          border-radius: 100px;
          background-color: $grayVeryLight;
          cursor: pointer;
          & svg {
            width: 10px;
            height: 10px;
            stroke: $black;
            transform: rotate(90deg);
          }
        }

        & .dateSwitcherRightButton {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 36px;
          height: 36px;
          border-radius: 100px;
          background-color: $grayVeryLight;
          cursor: pointer;

          & svg {
            width: 10px;
            height: 10px;
            stroke: $black;
            transform: rotate(-90deg);
          }
        }

        & .dateContainer {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          flex: 1;

          & .weekTitle {
            @include text_medium_bold;
          }

          & .dateTitle {
            @include text_x_small_regular;
            color: $grayDark;
          }
        }
      }
      & .tabContainer {
        display: flex;
        flex-direction: row;
        padding-bottom: 8px;
        border-bottom: 3px solid $grayVeryLight;
        position: relative;
        & .tabButton {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          @include text_small_bold;
          color: $grayDark;
        }

        & .tabButtonActive {
          color: $black;
        }

        & .tabIndicator {
          position: absolute;
          bottom: -3px;
          height: 3px;
          background: $primary;
        }
      }
    }
  }
}
