@import "../../scss/main.scss";

.overlay {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $overlay;
}

.modal {
  & .innerContainer {
    position: relative;
    width: 352px;
    margin-top: 100px;
    padding: 32px;
    border-radius: 24px;
    background: $white;

    & .title {
      @include desktop_h5;
    }

    & .closeBtn {
      position: absolute;
      top: 20px;
      right: 20px;
      stroke: $dark;
      cursor: pointer;
    }

    & h5 {
      @include desktop_h5;
      // margin-top: 20px;
    }

    & .subtitle {
      @include text_small_regular;
      color: $grayDark;
      margin-top: 15px;
      margin-bottom: 15px;
    }

    & .btnContainer {
      display: flex;
      gap: 20px;
    }
  }
}
