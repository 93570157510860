@import "../../../scss/main.scss";

.wrapper {
  padding-bottom: 30px;
  max-width: 1083px;

  & .loaderWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 80vh;
  }

  & .header {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    margin-bottom: 30px;
    margin-left: -8px;
    & .headerArrowButton {
      cursor: pointer;
      width: 32px;
      height: 32px;
      stroke: $black;
    }

    & .headerTitle {
      @include desktop_h5;
      font-weight: 600;
    }

    & .headerButtonsContainer {
      margin-left: auto;
      gap: 10px;
      display: flex;
      & .headerButton {
        width: 87px;
      }
    }
  }

  & .contentContainer {
    padding: 30px;
    border-radius: 24px;
    -webkit-box-shadow: 0px 0px 30px 0px rgba(227, 230, 236, 0.5);
    -moz-box-shadow: 0px 0px 30px 0px rgba(227, 230, 236, 0.5);
    box-shadow: 0px 0px 30px 0px rgba(227, 230, 236, 0.5);

    & .userInfoContainer {
      display: flex;
      align-items: center;

      & .userAvatar {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 100px;
        border-radius: 100px;
        background: $grayE430;
        position: relative;
        overflow: hidden;
        & svg {
          fill: $grayAf;
        }

        & .avatar {
          width: 100%;
          height: 100%;
        }
      }

      & .userBlock {
        margin-left: 20px;
        margin-right: 20px;
        flex: 1;

        & .userName {
          @include desktop_h4;
          font-weight: 600;
        }
      }

      & .followersBlock {
        display: flex;
        align-items: center;

        & .followersDivider {
          margin: 0 25px;
          height: 25px;
          width: 1px;
          background: $shadow;
        }

        & .followerItem {
          display: flex;
          flex-direction: column;
          & .followerValue {
            @include desktop_h4;
            font-weight: 600;
          }

          & .followerSubtitle {
            @include text_small_regular;
            color: $grayLight;
          }
        }
      }
    }

    & .tabButtonsContainer {
      display: flex;
      gap: 2px;
      margin-top: 25px;

      & .tabButton {
        padding: 8px 18px;
        border-radius: 12px;
        transition: all 0.2s linear;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        gap: 5px;

        &:hover {
          opacity: 0.8;
        }

        & .tabButtonTitle {
          @include text_small_bold;
          color: $deactive;
        }

        & .tabButtonTitleActive {
          color: $white;
        }

        & .tabButtonCounterWrapper {
          width: 22px;
          height: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100px;

          background-color: $shadow;
          & span {
            @include text_x_small_bold;
            color: $grayLight;
          }
        }
      }

      & .tabButtonActive {
        background: $primary;
      }
    }
  }
}
