@import "../../scss/main.scss";

.wrapper {
  & .loaderWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 375px;
  }
  & .dateHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;

    & .dateHeaderButton {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      border-radius: 100px;
      background-color: $grayVeryLight;
      cursor: pointer;

      & .leftArrow {
        stroke: $black;
        transform: rotate(90deg);
      }

      & .rightArrow {
        stroke: $black;
        transform: rotate(-90deg);
      }
    }

    & .dateTitle {
      @include text_small_medium;
      flex: 1;
      text-align: center;
    }
  }

  & .rangeSwitcher {
    display: flex;
    margin-top: 15px;
    position: relative;
    padding-bottom: 10px;

    & .rangeButton {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;

      & .rangeButtonValue {
        @include mobile_h2;
        line-height: 32px;
        font-weight: 600;
        position: relative;

        & .percentsContainer {
          background-color: rgba(54, 217, 14, 0.2);
          position: absolute;
          top: -10px;
          right: -20px;
          display: flex;
          align-items: center;
          border-radius: 30px;
          padding: 3px 6px 3px 0;

          & svg {
            fill: $green;
          }

          & .percentValue {
            font-size: 10px;
            font-weight: 400px;
            line-height: 14px;
            color: $green;
          }
        }

        & .percentsContainerRed {
          background-color: $redOpacity20;
          & svg {
            fill: $red;
          }

          & .percentValue {
            font-size: 10px;
            font-weight: 400px;
            line-height: 14px;
            color: $red;
          }
        }
      }

      & .rangeButtontitle {
        @include text_x_small_regular;
        font-size: 10px;
        color: $grayDark;
      }

      & .rangeButtonUnit {
        font-size: 16px;
        line-height: 16px;
        font-weight: 400;
      }
    }
    & .rangeIndicator {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 3px;
      background-color: $primary;
      border-radius: 100px;
    }
  }

  & .chartWrapper {
    position: relative;
    height: 222px;
    margin-top: 23px;
    margin-bottom: 37px;

    & .yAxisContainer {
      position: absolute;
      left: 0;
      top: -7px;
      bottom: -7px;
      width: 30px;
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;

      & .yAxisText {
        @include text_x_small_regular;
        color: $grayDark;
      }
    }

    & .gridContainer {
      left: 35px;
      top: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      & .gridLine {
        width: 100%;
        height: 1px;
        background-color: $grayVeryLight;
      }
    }

    & .xAxisContainer {
      position: absolute;
      left: 30px;
      right: 0;
      bottom: -22px;
      display: flex;
      flex-direction: row;
      & .xAxisText {
        flex: 1;
        text-align: center;
        @include text_x_small_regular;
        color: $grayDark;
      }
    }

    & .chartContainer {
      position: absolute;
      left: 35px;
      top: 0;
      bottom: 0;
      right: 0;
      display: flex;

      & .barContainer {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-end;
        flex: 1;

        & .caloriesInBar {
          width: 80px;
          background: $blueOpacity20;
          border: 3px solid $blue;
          border-radius: 3px;
        }

        & .caloriesBmrBar {
          width: 80px;
          background: $orangeOpacity20;
          border: 3px solid $orange;
          border-radius: 3px;
        }

        & .workoutBar {
          width: 80px;
          background: $springGreenOpacity20;
          border: 3px solid $springGreen;
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;
        }

        & .deficitBar {
          // flex: 1;
          position: relative;
          width: 80px;
          // background: $primary;
          // border: 3px solid $primary;
          & .leftLine {
            border-top: 2px dashed $primary;
            position: absolute;
            margin-bottom: -1px;
            // width: 30px;
          }

          & .targetDeficit {
            width: 100%;
            background: $primary;
            border: 3px solid $primary;
            border-radius: 3px;
          }

          & .superDeficitBar {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            overflow: hidden;
            border-radius: 3px;
            & .lineWrapper {
              width: 500px;
              height: 500px;
              display: flex;
              transform: rotate(45deg);
              & .line {
                width: 4px;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }

  & .footerChart {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 5px;
    column-gap: 15px;

    & .footerChartItem {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;

      & .footerChartItemIndicator {
        width: 8px;
        height: 8px;
        border-radius: 100px;
        border-width: 2px;
        border-style: solid;
      }

      & .footerChartItemIndicatorSuperDeficit {
        width: 8px;
        height: 8px;
        border-radius: 100px;
        display: flex;
        overflow: hidden;
        transform: rotate(-45deg);
        & .primary {
          background: $primary;
          flex: 1;
        }

        & .opacity {
          flex: 1;
          background: $primaryLight;
        }
      }

      & .footerChartItemTitle {
        @include text_x_small_regular;
        color: $textMain;
      }
    }
  }
}
