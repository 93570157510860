@import "../../scss/main.scss";

.wrapper {
  flex: 1;
  position: relative;
  & .title {
    @include text_x_large_bold;
    line-height: 24px;
  }

  & .progressTooltip {
    width: 117px;
    margin-bottom: 15px;
    margin-top: 20px;
  }

  & .progressTooltipInner {
    padding: 4px 10px;
    background-color: $black;
    border-radius: 17px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & .progressTooltipTitle {
    @include text_small_regular;
    font-size: 10px;
    line-height: 16px;
    color: $grayDark;
  }
  & .progressTooltipSubtitle {
    @include text_small_bold;
    margin-top: -2px;

    color: $white;
  }

  & .progressContainer {
    height: 20px;
    background-color: $orangeOpacity20;
    border-radius: 100px;
    // margin-top: 72px;
    position: relative;

    & .progress {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background: $orange;
      border-radius: 100px;
    }
  }

  & .contentContainer {
    margin: 0 55px;
    & .rowContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      & .weightItem {
        display: flex;
        flex-direction: column;
        margin-top: 5px;
        & .weightTitle {
          @include text_large_bold;
          line-height: 22px;
        }

        & .weightUnit {
          @include text_x_small_regular;
          line-height: 12px;
        }

        & .date {
          @include text_x_small_regular;
          color: $grayDark;
        }
      }
    }
  }

  & .deficit {
    position: absolute;
    font-size: 36px;
    font-weight: 600;
    color: $primary;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  & .warningContainer {
    padding: 8px 10px;
    border-radius: 10px;
    background-color: $primaryLight;
    margin: 25px 55px;
    & .warningTitle {
      @include text_x_small_bold;
      color: $primary;
    }
  }
}
