@import "../../scss/main.scss";

.overlay {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $overlay;
}

.modal {
  & .innerContainer {
    position: relative;
    width: 1017px;
    margin-top: 100px;
    padding: 32px;
    border-radius: 24px;
    background: $white;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    & .title {
      @include desktop_h5;
    }

    & .closeButton {
      position: absolute;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 20px;
      cursor: pointer;
      right: 20px;
      & svg {
        stroke: $dark;
      }
    }

    & .imageContainer {
      width: 555px;
      height: 400px;
      border-radius: 10px;
      background: $grayE430;
      object-fit: cover;

      display: flex;
      align-items: center;
      justify-content: center;

      & svg {
        fill: $grayAf;
      }
    }

    & .commentContainer {
      flex: 1;
      height: 650px;

      & .userBlock {
        display: flex;
        gap: 10px;

        & .avatar {
          width: 36px;
          height: 36px;
          border-radius: 100px;
          background: $grayE430;
        }

        & .userName {
          @include text_small_bold;
        }

        & .date {
          @include text_x_small_regular;
          color: $grayDark;
        }
      }

      & .description {
        margin-top: 8px;
        margin-bottom: 10px;

        @include text_small_regular;
      }

      & .iconItem {
        display: flex;
        gap: 5px;
        align-items: center;

        & svg {
          fill: $grayDark;
        }

        & span {
          @include text_small_bold;
          color: $grayDark;
        }
      }

      & .postDivider {
        width: 100%;
        height: 1px;
        background: $shadow;
        margin: 10px 0;
      }

      & .sectionTitle {
        @include text_medium_bold;
        margin-bottom: 10px;
      }
    }
  }
}
