@import "../../scss/main.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  & .iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 10px;
  }

  & .value {
    @include text_small_bold;
    margin-top: 5px;
  }
  & .title {
    @include text_x_small_regular;
    color: $grayDark;
  }
}
