@import "../../scss/main.scss";

.wrapper {
  padding: 3px 10px 4px;
  background: $additional;
  border-radius: 8px;

  & span {
    @include text_x_small_bold;
    color: $primary;
  }

  & .counterWrapper {
    background-color: $white;
    width: 20px;
    height: 20px;
  }
}
