@import "../../../scss/main.scss";

.wrapper {
  padding-bottom: 30px;
  max-width: 1520px;

  & .header {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    margin-bottom: 42px;
    margin-left: -8px;
    & .headerArrowButton {
      cursor: pointer;
      width: 32px;
      height: 32px;
      stroke: $black;
    }

    & .headerTitle {
      @include desktop_h5;
      font-weight: 600;
    }
  }

  & .sectionDivider {
    height: 1px;
    background: $grayE4;
    margin: 20px 0;
  }

  & .sectionTitle {
    @include desktop_h5;
    font-weight: 600;
  }
  & .servingContainer {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    gap: 10px;

    & .unitSelector {
      width: 180px;
    }

    & .macroWrapper {
      flex: 1;
      margin-left: 20px;
      & .macroContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;

        & .macroItem {
          display: flex;
          flex-direction: column;

          & .macroValue {
            @include text_x_large_bold;
            line-height: 24px;
          }

          & .macroTitle {
            @include text_small_regular;
            color: $grayDark;
          }
        }
      }
      & .macroSectionTitle {
        color: $grayLight;
        @include text_x_small_medium;
      }
    }
  }
  & .macroContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    & .macroItem {
      display: flex;
      flex-direction: column;

      & .macroValue {
        @include text_x_large_bold;
        line-height: 24px;
      }

      & .macroTitle {
        @include text_small_regular;
        color: $grayDark;
      }
    }
  }

  & .addIngredeintButtonContainer {
    display: flex;
    align-items: center;
    gap: 30px;
  }

  & .macroSectionTitle {
    color: $grayLight;
    @include text_x_small_medium;
  }
  & .recipeInfoContainer {
    display: flex;
    flex-direction: row;
    gap: 50px;

    & .recipeInfoBlock {
      display: flex;
      flex-direction: column;
      gap: 20px;
      flex: 1;
    }
    & .recipeImageBlock {
      max-width: 489px;
      width: 100%;
      overflow: hidden;

      & .buttonsContainer {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 20px;

        & .uploadImageButton {
          display: flex;
          align-items: center;
          justify-content: center;
          background: $primary;
          border-radius: 8px;
          width: 166px;
          height: 40px;
          @include text_small_bold;
          color: $white;
          cursor: pointer;
          transition: all 0.2s linear;
          &:hover {
            opacity: 0.8;
          }
        }
        & .deleteImageButton {
          display: flex;
          align-items: center;
          justify-content: center;
          background: $grayE4;
          border-radius: 8px;
          width: 166px;
          height: 40px;
          @include text_small_bold;
          cursor: pointer;
          transition: all 0.2s linear;
          &:hover {
            opacity: 0.8;
          }
        }
        & .uploadImageInput {
          display: none;
        }
      }

      & .imageContainer {
        height: 332px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $grayE430;
        border-radius: 20px;
        position: relative;
        overflow: hidden;

        & .recipeIcon {
          fill: $grayAf;
        }

        & .image {
          max-width: 100%;
          object-fit: cover;
        }
      }
    }
  }

  & .ingredientListHeader {
    display: flex;
    gap: 20px;
    margin-top: 20px;
    & .infoContainer {
      display: flex;
      gap: 10px;
      flex: 1.2;

      & .name {
        flex: 1;
      }

      & .amount {
        flex: 0.5;
      }
      & .unit {
        flex: 1;
      }
    }

    & .macroContainer {
      display: flex;
      gap: 10px;
      flex: 1;
      margin-right: 26px;

      & .calories {
        flex: 1.2;
      }

      & .macroItem {
        flex: 1;
      }
    }

    & span {
      @include text_x_small_medium;
      color: $grayDark;
    }
  }

  & .ingredientList {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  & .preparationsList {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;

    & .preparationMethod {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      & .preparationMethodInput {
        flex: 1;
      }

      & .preparationMethodDeleteButton {
        width: 26px;
        height: 26px;
        margin-top: 25px;
        cursor: pointer;
      }
    }
  }

  & .tagsContainer {
    margin-top: 20px;
    padding: 15px;
    border-radius: 8px;
    background: #fafafa;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    & .tagsLoader {
      height: 200px;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  & .footer {
    display: flex;

    & .leftContainer {
      display: flex;
      gap: 10px;
      flex: 1;
    }
    & .rightContainer {
      flex: 1;

      & .rightButton {
        width: 270px;
        margin-left: auto;
      }
    }
  }

  & .addButton {
    position: relative;
    padding: 16px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 188px;
    gap: 8px;
    border: 2px dashed $primary;
    border-radius: 16px;
    cursor: pointer;
    & svg {
      stroke: $primary;
    }

    & .title {
      @include text_small_bold;
      color: $primary;
    }
  }
}
