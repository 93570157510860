@import "../../scss/main.scss";

.wrapper {
  height: 199px;
  position: relative;
  margin-top: 28px;

  & .yAxisContainer {
    position: absolute;
    left: 0;
    top: -7px;
    bottom: -7px;
    display: flex;
    width: 32px;
    flex-direction: column-reverse;
    justify-content: space-between;

    & .yAxisText {
      font-size: 10px;
      line-height: 14px;
      color: $grayDark;
    }
  }

  & .xAxisContainer {
    position: absolute;
    height: 56px;
    bottom: -61px;
    left: 37px;
    right: 0;
    display: flex;
    & .xAxisItem {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      & .avatar {
        width: 28px;
        height: 28px;
        border-radius: 100px;
        background: $grayVeryLight;
        display: flex;
        align-items: center;
        justify-content: center;

        & svg {
          width: 16px;
          height: 16px;
          fill: $grayAf;
        }
      }

      & .name {
        font-size: 13px;
        line-height: 24px;
        color: $grayDark;
      }
    }
  }

  & .gridContainer {
    position: absolute;
    left: 37px;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & .gridLine {
      height: 1px;
      width: 100%;
      background-color: $grayVeryLight;
    }
  }

  & .chartWrapper {
    position: absolute;
    left: 37px;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;

    & .barItemWrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;

      & .trophyIcon {
        margin-bottom: 4px;
      }

      & .barItem {
        width: 40px;
        border-radius: 3px;
        background: $primary;
      }
    }
  }
}
