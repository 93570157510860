@import "../../scss/main.scss";

.wrapper {
  min-width: 50px;
  & .value {
    @include text_x_small_bold;
  }

  & .title {
    @include text_x_small_regular;
    color: $grayDark;
    margin-top: -3px;
  }

  & .opacity {
    color: $gray;
  }
}
