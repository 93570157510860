@import "../../scss/main.scss";

.wrapper {
  & .dateHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;

    & .dateHeaderButton {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      border-radius: 100px;
      background-color: $grayVeryLight;
      cursor: pointer;

      & .leftArrow {
        stroke: $black;
        transform: rotate(90deg);
      }

      & .rightArrow {
        stroke: $black;
        transform: rotate(-90deg);
      }
    }

    & .dateTitle {
      @include text_small_medium;
      flex: 1;
      text-align: center;
    }
  }

  & .weekDaysNamesContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 13px;

    & .weekName {
      @include text_small_bold;
      color: $grayDark;
      text-align: center;
    }
  }

  & .weekContainer {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    flex-wrap: wrap;
    row-gap: 5px;
    margin-top: 5px;
  }

  & .dayWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    & .dayStreakContainer {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      & .day {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include text_small_bold;
      }
    }
    & .daySteakMarked {
      background-color: $primaryLight;
    }
    & .daySteakFirstMarked {
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
    }

    & .dayStreakLastMarked {
      border-top-right-radius: 100px;
      border-bottom-right-radius: 100px;
    }

    & .daySteakOneMarked {
      width: 40px;
      background-color: $primaryLight;
      border-radius: 100px;
    }

    & .daySteakOrange {
      width: 40px;
      background-color: $red;
      border-radius: 100px;
    }

    & .inactiveDay {
      color: $grayMedium;
    }

    & .today {
      border-radius: 100px;
      background-color: $primary;
      color: $white;
    }

    & .orange {
      color: $red;
    }
  }
}
