@import "../../scss/main.scss";

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.overlay {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $overlay;
  overflow-y: auto;
}

.modal {
  min-height: 100%;

  & .innerContainer {
    position: relative;
    width: 720px;
    margin-top: 50px;
    padding: 32px;
    border-radius: 24px;
    background: $white;

    & h6 {
      @include desktop_h5;
      font-weight: 500;
    }
    & .closeButton {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 48px;
      height: 48px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      & svg {
        stroke: $dark;
      }
    }

    & .inputContainer {
      margin-top: 22px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      & .originaName {
        @include text_x_small_regular;
        color: $grayLight;
      }

      & .sectionTitle {
        @include text_x_small_medium;
        color: $grayLight;
      }
    }
    & .sectionTitle {
      @include text_x_small_medium;
      color: $grayLight;
      margin-bottom: 10px;
      margin-top: 10px;
    }
    & .editableUnitWrapper {
      & .editableUnitDivider {
        width: 100%;
        height: 1px;
        background-color: $grayE4;
        margin: 15px 0;
      }
    }
    & .macroContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      & .refreshButton {
        fill: $primary;
        min-width: 24px;
        min-height: 24px;
        margin-top: 24px;
        cursor: pointer;
      }

      & .refreshButtonRotate {
        -webkit-animation: rotating 2s ease-in-out forwards;
        -moz-animation: rotating 2s ease-in-out forwards;
        -ms-animation: rotating 2s ease-in-out forwards;
        -o-animation: rotating 2s ease-in-out forwards;
        animation: rotating 2s ease-in-out forwards;
      }
    }

    & .addUnitButton {
      width: 120px;
      margin-top: 10px;
    }

    & .addUnitTitle {
    }
    & .macroContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      & .refreshButton {
        fill: $primary;
        min-width: 24px;
        min-height: 24px;
        margin-top: 24px;
        cursor: pointer;
      }

      & .refreshButtonRotate {
        -webkit-animation: rotating 2s ease-in-out forwards;
        -moz-animation: rotating 2s ease-in-out forwards;
        -ms-animation: rotating 2s ease-in-out forwards;
        -o-animation: rotating 2s ease-in-out forwards;
        animation: rotating 2s ease-in-out forwards;
      }
    }
  }
  & .unitsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 15px;
  }
}
.pcsInput {
  width: 250px;
}
