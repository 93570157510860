@import "../../scss/main.scss";

.wrapper {
  padding: 27px 32px;
  border-radius: 24px;
  background: $white;
  -webkit-box-shadow: 0px 0px 30px 0px $shadow;
  -moz-box-shadow: 0px 0px 30px 0px $shadow;
  box-shadow: 0px 0px 30px 0px $shadow;
  position: absolute;
  right: 30px;
  top: 230px;
  z-index: 9999;
  width: 298px;

  & .title {
    @include desktop_h5;
    font-weight: 500;
  }

  & .sectionDevider {
    height: 1px;
    margin: 15px 0;
    background: $grayE4;
  }

  & .listContainer {
    max-height: 250px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &::-webkit-scrollbar-track {
    }

    &::-webkit-scrollbar {
      background-color: $white;
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $grayE4;
      border-radius: 100px;
    }
  }

  & .selectorItem {
    display: flex;
    align-items: center;
    gap: 8px;

    & .selectorItemText {
      @include text_x_small_bold;
    }
  }

  & .sectionHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 10px;

    & .sectionTitle {
      @include text_small_bold;
    }

    & .arrowOpen {
      transition: all 0.2s linear;

      transform: rotate(-180deg);
      stroke: $black;
    }

    & .arrowClosed {
      transition: all 0.2s linear;
      transform: rotate(0deg);

      stroke: $black;
    }
  }

  & .selectorRowContainer {
    display: flex;
    gap: 15px;

    & .selector {
      flex: 1;
    }
  }

  & .resetButton {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    & div {
      transition: all 0.2s linear;
      cursor: pointer;

      @include text_small_bold;
      color: $deactive;
      &:hover {
        color: $grayLight;
      }
    }
  }
}
