@import "../../scss/main.scss";

.overlay {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $overlay;
  overflow-y: auto;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  & .innerContainer {
    position: relative;
    width: 450px;
    margin-top: 50px;
    padding: 32px;
    border-radius: 24px;
    background: $white;

    & h6 {
      @include desktop_h5;
      font-weight: 500;
    }
    & .closeButton {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 48px;
      height: 48px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      & svg {
        stroke: $dark;
      }
    }

    & .inputContainer {
      margin-top: 22px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 15px;
    }
  }
}
