@import "../../scss/main.scss";

.wrapper {
  display: flex;
  align-items: center;
  padding: 15px 0px;
  border-bottom: 1px solid $shadow;
  position: relative;
  cursor: pointer;
  transition: all linear 0.2s;

  &:hover {
    background: $grayVeryLight;
  }
  & .nameContainer {
    flex: 1;
    display: flex;
    gap: 15px;

    & .imageWrapper {
      width: 70px;
      height: 60px;
      border-radius: 10px;
      background: $grayE450;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      & svg {
        fill: $grayAf;
        width: 25px;
        height: 25px;
      }
      & .image {
        width: 100%;
        height: 100%;
        overflow: hidden;
        object-fit: cover;
      }
    }

    & .name {
      @include text_medium_bold;
    }

    & .macroContainer {
      display: flex;
      gap: 10px;
    }
  }

  & .categoryContainer {
    flex: 0.4;
    & .category {
      @include text_small_regular;
    }
  }

  & .tagsContainer {
    flex: 0.6;
    display: flex;
    align-items: center;
    & .tagList {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      flex: 1;
    }

    & svg {
      cursor: pointer;
      fill: $black;
    }
  }
}

.menuWrapper {
  position: absolute;
  right: -20px;
  top: 55px;
  width: 135px;
  box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.14);
  border-radius: 15px;
  background: #fff;
  z-index: 99;
  padding: 10px;

  .menuButton {
    display: flex;
    align-items: center;
    gap: 3px;
    width: auto;
    background: unset;
    padding: 6px 8px;
    border-radius: 10px;
    transition: all linear 0.2s;
    cursor: pointer;

    & .editIcon {
      fill: $dark;
    }

    & .deleteIcon {
      fill: $red;
    }

    & .deleteLoader {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      background: #f8f8f8;
    }

    & span {
      @include text_small_bold;
      margin-left: 10px;
      color: $dark;
    }
  }
}
