@import "../../scss/main.scss";

.categoryEditItem {
  border-radius: 8px;
  border: 1px solid $grayAf;
  overflow: hidden;
  // padding-left: 13px;
  padding-right: 3px;
  display: flex;
  align-items: center;

  & .categoryEditItemCancel {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    & svg {
      stroke: $red;
    }
  }

  & .categoryEditItemSave {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    & svg {
      fill: $primary;
    }
  }

  & .editInput {
    border: none;
    outline: none;
    height: 30px;
    max-width: 100px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
  }
}

.categoryItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 0px 6px 12px;
  background: $additional;
  border-radius: 8px;
  cursor: pointer;
  position: relative;

  & .dotMenu {
    fill: $primary;
  }

  & span {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: $primary;
  }

  & .counterWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    width: 22px;
    height: 22px;
    background: $white;
    margin-left: 5px;

    & span {
      @include text_x_small_bold;
    }
  }

  & .menuWrapper {
    width: 109px;
    height: 87px;
    position: absolute;
    box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.14);
    border-radius: 15px;
    background: #fff;
    z-index: 99;
    top: 40px;
    padding: 10px;

    & .menuButton {
      display: flex;
      align-items: center;
      gap: 3px;
      padding: 6px 8px;
      border-radius: 10px;
      gap: 10px;
      transition: all linear 0.2s;

      & svg {
        fill: $black;
      }

      &:hover {
        background: #f8f8f8;
      }

      & span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #000;
      }
    }
  }
}
