@import "../../../scss/main.scss";

.wrapper {
  padding-bottom: 30px;
  position: relative;
  & .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .title {
      @include desktop_h4;
    }

    & .headerAddButton {
      width: 230px;
    }
  }

  & .contentContainer {
    background: $white;
    border-radius: 24px;
    margin-top: 30px;
    padding: 30px;

    -webkit-box-shadow: 0px 0px 30px 0px $shadow;
    -moz-box-shadow: 0px 0px 30px 0px $shadow;
    box-shadow: 0px 0px 30px 0px $shadow;

    & .headerButtonsContainer {
      display: flex;
      gap: 2px;
      margin-bottom: 28px;
      & .tabButton {
        padding: 8px 18px;
        border-radius: 12px;
        transition: all 0.2s linear;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        gap: 5px;

        &:hover {
          opacity: 0.8;
        }

        & .tabButtonTitle {
          @include text_small_bold;
          color: $deactive;
        }

        & .tabButtonTitleActive {
          color: $white;
        }

        & .tabButtonCounterWrapper {
          width: 22px;
          height: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100px;

          background-color: $shadow;
          & span {
            @include text_x_small_bold;
            color: $grayLight;
          }
        }
      }

      & .tabButtonActive {
        background: $primary;
      }
    }

    & .mainLoader {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 70vh;
    }

    & .contentContainerHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & .searchContainer {
        max-width: 447px;
        width: 100%;
      }

      & .filterButton {
        width: 48px;
        height: 48px;
        border-radius: 100px;
        background: $white;
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        -webkit-box-shadow: 0px 0px 30px 0px $shadow;
        -moz-box-shadow: 0px 0px 30px 0px $shadow;
        box-shadow: 0px 0px 30px 0px $shadow;
        & svg {
          stroke: $black;
        }
      }

      & .activeFilterButton {
        background: $primary;
        & svg {
          stroke: $white;
        }
      }
    }

    & .sectionHeader {
      display: flex;
      align-items: center;
      padding-bottom: 17px;
      border-bottom: 1px solid $shadow;
      margin-top: 29px;

      & .nameBlock {
        flex: 1;
        display: flex;
        align-items: center;
        @include text_x_small_bold;
        color: $deactive;
        gap: 3px;

        & svg {
          stroke: $deactive;
        }
      }
      & .genderBlock {
        flex: 0.6;
        display: flex;
        align-items: center;
        @include text_x_small_bold;
        color: $deactive;
        gap: 3px;

        & svg {
          stroke: $deactive;
        }
      }

      & .deficitBlock {
        flex: 0.7;
        display: flex;
        align-items: center;
        @include text_x_small_bold;
        color: $deactive;
        gap: 3px;

        & svg {
          stroke: $deactive;
        }
      }
    }

    & .listContainer {
      & .loaderWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50vh;
      }
    }
  }
}
