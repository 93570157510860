@import "../../scss/main.scss";

.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 50vh;
}

.wrapper {
  flex: 1;
  & .title {
    @include text_x_large_bold;
    font-weight: 600;
  }

  & .dateHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;

    & .dateHeaderButton {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      border-radius: 100px;
      background-color: $grayVeryLight;
      cursor: pointer;

      & .leftArrow {
        stroke: $black;
        transform: rotate(90deg);
      }

      & .rightArrow {
        stroke: $black;
        transform: rotate(-90deg);
      }
    }

    & .dateTitle {
      @include text_small_medium;
      flex: 1;
      text-align: center;
    }
  }

  & .daysList {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
  }

  & .mealSections {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    gap: 10px;
  }
  & .macroContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
  }

  & .mealPlanContainer {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
