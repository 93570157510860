@import "../../../scss/main.scss";

.wrapper {
  padding-bottom: 30px;
  & .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .title {
      @include desktop_h4;
      & .countRecipe {
        @include text_large_bold;
        color: $grayDark;
      }
    }

    & .headerAddButton {
      width: 230px;
    }
  }

  & .contentContainer {
    background: $white;
    border-radius: 24px;
    margin-top: 30px;
    padding: 30px;

    -webkit-box-shadow: 0px 0px 30px 0px $shadow;
    -moz-box-shadow: 0px 0px 30px 0px $shadow;
    box-shadow: 0px 0px 30px 0px $shadow;

    & .contentContainerHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      & .searchContainer {
        max-width: 447px;
        width: 100%;
      }

      & .filterButton {
        width: 48px;
        height: 48px;
        border-radius: 100px;
        background: $white;
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        -webkit-box-shadow: 0px 0px 30px 0px $shadow;
        -moz-box-shadow: 0px 0px 30px 0px $shadow;
        box-shadow: 0px 0px 30px 0px $shadow;
        & svg {
          stroke: $black;
        }
      }

      & .activeFilterButton {
        background: $primary;
        & svg {
          stroke: $white;
        }
      }
    }

    & .mainLoader {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 70vh;
    }

    & .sectionHeader {
      display: flex;
      align-items: center;
      padding-bottom: 17px;
      border-bottom: 1px solid $shadow;
      margin-top: 29px;

      & .nameBlock {
        flex: 1;
        display: flex;
        align-items: center;
        @include text_x_small_bold;
        color: $deactive;
        gap: 3px;
        cursor: pointer;

        & svg {
          stroke: $deactive;
        }
      }

      & .categoryBlock {
        flex: 0.8;
        display: flex;
        align-items: center;
        @include text_x_small_bold;
        color: $deactive;
        gap: 3px;

        & svg {
          stroke: $deactive;
        }
      }

      & .dateBlock {
        flex: 0.4;
        display: flex;
        align-items: center;
        @include text_x_small_bold;
        color: $deactive;
        gap: 3px;
        cursor: pointer;

        & svg {
          stroke: $deactive;
        }
      }

      & .activeSortValue {
        color: $black;

        & svg {
          stroke: $black;
        }
      }

      & .sectionHeaderArrow {
        transition: all 0.2s linear;
      }

      & .sectionHeaderArrowUp {
        transform: rotate(180deg);
      }

      & .dateBlockActive {
        & svg {
          transition: all 0.2s linear;
          transform: rotate(180deg);
        }
      }
    }
    & .listContainer {
      & .loaderWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50vh;
      }

      & .emptyTitle {
        @include text_small_regular;
        align-self: center;
        text-align: center;
        margin-top: 110px;
        margin-bottom: 80px;
        color: $deactive;
      }
    }
  }
}
