@import "../../scss/main.scss";

.input {
  position: relative;
  & .hideButton {
    position: absolute;
    right: 0;
    right: 23px;
    @include text_x_small_medium;
    cursor: pointer;
    color: $deactive;
  }

  & .inputWrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

    & .inputPassword {
      padding-right: 80px;
    }
  }

  &__main {
    width: 100%;
    height: 56px;
    background-color: $grayE430;
    border-radius: 8px;
    padding: 15px 18px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    caret-color: $primary;
    color: #000;
    @include text_small_bold;
    &::placeholder {
      color: $grayLight;
    }
    border: 1px solid transparent;

    &.error {
      border-color: $red;
    }

    &.borderError {
      border-color: $red;
    }

    &:focus {
      outline: none;
      border-color: $primary;
      border-width: 2px;
    }
  }
  &:not(:last-child) {
    // margin-bottom: 18px;
  }
  span {
    display: block;
    color: $grayLight;
    margin-bottom: 10px;
    @include text_x_small_medium;
  }
  p {
    margin-top: 5px;
    font-size: 11px;
    color: $red;
    line-height: 18px;
  }
}

.labelWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  & .iconWrapper {
    position: relative;
    height: min-content;
    margin-top: -5px;
    & .infoIcon {
      fill: $grayLight;
      cursor: pointer;
      position: relative;
    }

    & .popUpContainer {
      position: absolute;
      top: -117px;
      transform: translate(-50%);
      left: 50%;
      z-index: 999999999999;
      width: 190px;
      display: flex;
      flex-direction: column;
      align-items: center;
      & .popUpInner {
        padding: 4px 10px;
        background: #000;
        border-radius: 10px;
        margin-bottom: 0;
        & span {
          @include text_x_small_bold;
          font-size: 12px;
          color: #fff;
        }
      }

      & .triangle {
        fill: #000;
      }
    }
  }
}
