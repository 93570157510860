@import "../../scss/main.scss";

.overlay {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $overlay;
  z-index: 99999999999999;
}

.modal {
  & .innerContainer {
    position: relative;
    width: 530px;
    margin-top: 100px;
    padding: 32px;
    border-radius: 24px;
    background: $white;

    & .title {
      @include desktop_h5;
    }

    & .optionsContainer {
      margin-top: 22px;
      // padding: 25px 20px;
      border-radius: 15px;
      // background: $grayE425;
      display: flex;
      flex-direction: column;
      gap: 25px;
      margin-bottom: 22px;

      & .radioOption {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;

        & .radioTitle {
          @include text_medium_bold;
          // margin-bottom: 5px;
        }
      }

      & .blockReasonWrapper {
        & .title {
          @include text_small_regular;
          color: $textMain;
        }
        & .reasonList {
          display: flex;
          flex-direction: column;
          gap: 12px;
          margin-top: 15px;
          & .radioOption {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;

            & .radioTitle {
              @include text_small_regular;
              color: $textMain;
            }
          }
        }
      }
    }

    & .closeBtn {
      position: absolute;
      top: 20px;
      right: 20px;
      stroke: $dark;
      cursor: pointer;
    }

    & h5 {
      @include desktop_h5;
      font-weight: 600;
      // margin-top: 20px;
    }

    & .btnContainer {
      margin-top: 30px;
      display: flex;
      gap: 20px;
    }
  }
}
