@import "../../../../../scss/main.scss";

.wrapper {
  display: flex;
  margin-top: 25px;
  & .progress {
    flex: 1;
  }
  & .divider {
    width: 1px;
    margin: 0 32px;
    background-color: $shadow;
  }

  & .leftContainer {
    max-width: 509px;
    & .sectionDivider {
      width: 100%;
      height: 1px;
      background-color: $shadow;
      margin: 20px 0;
    }
  }
}
