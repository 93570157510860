@import "../../scss/main.scss";

.wrapper {
  & .title {
    @include text_x_large_bold;
  }

  & .circularProgressContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;

    & .circularWrapper {
      width: 160px;
      height: 160px;

      & .circularInner {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
      }

      & .consistencyIcon {
        fill: $orange;
        margin-top: 30px;
      }

      & .calendarIcon {
        fill: $primary;
        margin-top: 30px;
      }

      & .value {
        font-size: 28px;
        line-height: 32px;
        font-weight: 600;
        margin-top: 10px;
      }

      & .unit {
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
      }

      & .subtitle {
        font-size: 10px;
        font-weight: 400;
        color: $grayDark;
        margin-top: 5px;
      }
    }
  }
}
