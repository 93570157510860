@import "../../scss/main.scss";
.wrapperSelected {
  background-color: $primary;

  &:hover {
    opacity: 0.7;
    background-color: $primary !important;
  }
}

.wrapperDisabled {
  opacity: 0.6 !important;
}
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40px;
  padding: 10px 6px 6px 6px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s linear;
  position: relative;
  &:hover {
    background-color: $primaryLight;
  }

  & .dayTitle {
    @include text_x_small_regular;
    color: $grayDark;
  }

  & .dayTitleSelected {
    color: $white;
  }

  & .dayNumberWrapper {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
  }

  & .dayNumberWrapperSelected {
    background-color: $white;
  }

  & .dayNumber {
    @include text_small_bold;
  }

  & .dayNumberSelected {
    color: $primary;
  }

  & .completedIndicator {
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 100px;
    background-color: $green;
    bottom: 1px;
  }
}
