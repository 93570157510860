@import "../../scss/main.scss";

.wrapper {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  flex-direction: column;
  gap: 50px;
  width: 100vw;
  height: 100vh;
  padding-top: 50px;

  & img {
  }

  & .signInForm {
    width: 460px;
    background: #ffffff;
    box-shadow: 0px 15px 80px rgba(227, 230, 236, 0.8);
    border-radius: 24px;
    padding: 30px;

    & .input {
      margin-bottom: 20px;
    }

    & .checkboxContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      margin-bottom: 20px;
      & span {
        @include text_x_small_regular;
      }
    }
  }
}
