@import "../../scss/main.scss";

.wrapper {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;

  & .iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    border-radius: 100px;
    background: $springGreenOpacity20;
    position: relative;
    & svg {
      fill: $springGreen;
    }
    & .checkIconWrapper {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $green;
      border-radius: 100px;
      position: absolute;
      right: -5px;
      top: -5px;

      & svg {
        width: 14px;
        height: 14px;
        fill: $white;
      }
    }
  }

  & .contentContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    & .title {
      @include text_medium_bold;
    }

    & .minutes {
      @include text_small_regular;
      color: $grayDark;
    }
  }

  & .calories {
    @include text_small_bold;
    color: $green;
  }
}
