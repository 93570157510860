@import "../../scss/main.scss";

.wrapper {
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  border-bottom: 1px solid $shadow;

  & .nameBlock {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 18px;

    & .index {
      @include text_medium_bold;
      color: $grayDark;
      width: 24px;
    }

    & .avatar {
      width: 44px;
      height: 44px;
      border-radius: 100px;
      object-fit: cover;
    }

    & .contentContainer {
      display: flex;
      flex-direction: column;
      & .name {
        @include text_medium_bold;
      }
      & .years {
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        color: $grayDark;
      }
    }
  }

  & .genderBlock {
    flex: 0.6;
    display: flex;
    align-items: center;
    & .genderTitle {
      @include text_small_regular;
      color: #11142d;
    }
  }

  & .deficitBlock {
    flex: 0.7;
    display: flex;
    align-items: center;

    & .deficitTitle {
      @include text_small_regular;
      color: #11142d;
    }

    & .arrowButton {
      width: 24px;
      height: 24px;
      border-radius: 100px;
      background-color: $primary;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      transform: rotate(-90deg);
      & svg {
        stroke: $white;
      }
    }
  }
}
