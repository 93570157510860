@import "../../scss/main.scss";
.checkedWrapper {
  padding: 8px 10px;
  display: flex;
  align-items: center;
  gap: 7px;
  border-radius: 8px;
  background-color: $primary;
  cursor: pointer;
}
.uncheckedWrapper {
  padding: 8px 10px;
  display: flex;
  align-items: center;
  gap: 7px;
  border-radius: 8px;
  background-color: #dfd4ff;
  cursor: pointer;
}

.uncheckedTitle {
  @include text_x_small_bold;
  color: $primary;
}
.checkedTitle {
  @include text_x_small_bold;
  color: $white;
}

.checked {
  display: flex;
  width: 16px;
  height: 16px;
  align-items: center;
  justify-content: center;

  & svg {
    fill: $white;
  }
}

.unchecked {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background-color: $white;
}
