@import "../../scss/main.scss";

button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  outline: none;
  border: solid 2px transparent;
  font-family: $primary-font;
  width: 100%;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.xSmall {
  padding: 8px 18px;
  @include text_x_small_bold;
  width: max-content;
  border-radius: 12px;
}

.disabled {
  background-color: $grayE4;
  &:hover {
  }
}

.mobile {
  @include text_x_small_bold;
  line-height: 14px;
  border-radius: 10px;
  padding: 4px 6px;
  width: auto;
}

.small {
  padding: 14px 15px;
  @include text_small_bold;
  line-height: 14px;
  border-radius: 16px;
}

.medium {
  padding: 18px 18px;
  @include text_small_bold;
  line-height: 16px;
  border-radius: 16px;
}

.large {
  padding: 24px 24px;
  @include text_medium_bold;
  line-height: 20px;
}

.filled {
  background-color: $primary;
  color: $white;
  transition: all 0.2s linear;

  &:hover {
    opacity: 0.9;
  }
}

.inactive {
  background-color: transparent;
  color: $deactive;
  transition: all 0.2s linear;

  &:hover {
    background-color: $primaryLight;
  }
}

.outlined {
  border-width: 2px;
  border-style: solid;
  line-height: 16px;
  border-color: $grayE4;
  background-color: $white;

  width: 100%;
}

.outlinedDashed {
  border-width: 2px;
  border-style: dashed;
  @include mobile_h5;
  line-height: 16px;
}

.titleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
