@import "../../scss/main.scss";

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
  border-top: 1px solid $shadow;
  position: relative;
  &:last-child {
    border-bottom: 1px solid $shadow;
  }

  & .nameContainer {
    flex: 1;

    & .name {
      @include text_medium_bold;
    }

    & .brandName {
      @include text_medium_regular;
      color: $textMain;
    }

    & .macroContainer {
      display: flex;
      flex-direction: row;
      gap: 25px;
    }
  }

  & .categoryContainer {
    flex: 0.8;

    & p {
      @include text_small_regular;
    }
  }

  & .dateContainer {
    flex: 0.4;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    & p {
      @include text_small_regular;
      color: $grayLight;
    }

    & svg {
      fill: $dark;
      cursor: pointer;
    }
  }
}

.menuWrapper {
  position: absolute;
  right: -20px;
  top: 55px;
  width: 135px;
  box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.14);
  border-radius: 15px;
  background: #fff;
  z-index: 99;
  padding: 10px;

  & .menuButton {
    display: flex;
    align-items: center;
    gap: 3px;
    padding: 6px 8px;
    border-radius: 10px;
    transition: all linear 0.2s;
    cursor: pointer;

    & .editIcon {
      fill: $dark;
    }

    & .deleteIcon {
      fill: $red;
    }

    & .deleteLoader {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      background: #f8f8f8;
    }

    & span {
      @include text_small_bold;
      margin-left: 10px;
      color: $dark;
    }
  }
}

.perServing {
  @include text_small_regular;
  margin-bottom: 15px;
}
