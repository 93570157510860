@import "../../../scss/main.scss";

.wrapper {
  padding-bottom: 30px;
  & .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .title {
      @include desktop_h4;
    }
  }

  & .contentContainer {
    background: $white;
    border-radius: 24px;
    margin-top: 30px;
    padding: 30px;

    -webkit-box-shadow: 0px 0px 30px 0px $shadow;
    -moz-box-shadow: 0px 0px 30px 0px $shadow;
    box-shadow: 0px 0px 30px 0px $shadow;

    & .mainLoader {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 70vh;
    }

    & .chartsContainer {
      display: flex;

      & .statisticsContainer {
        width: 213px;

        & .allUsers {
          padding-bottom: 15px;
          border-bottom: 1px solid $grayE4;
        }
        & .todayBlock {
          padding-top: 15px;

          & .percentWrapper {
            display: flex;
            align-items: center;

            & .percentContainer {
              margin-left: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 16px;
              height: 16px;
              border-radius: 100px;
              background: $green;

              & svg {
                transform: rotate(180deg);
              }
            }

            & .percentContainerNegative {
              background: $red;
              & svg {
                transform: rotate(0deg);
              }
            }
            & .percentTitle {
              @include text_x_small_regular;
              font-size: 12px;
              margin-left: 4px;
              color: $green;
            }

            & .percentTitleNegative {
              color: $red;
            }
          }
        }
      }

      & .chart {
        flex: 1;
      }
    }

    & .infoContainer {
      display: flex;
      flex-wrap: wrap;
      margin-top: 30px;

      & .infoBlock {
        flex: 1;
        padding: 25px 24px;
        border-top: 1px solid $grayE4;
        border-bottom: 1px solid $grayE4;

        &:first-child {
          padding: 25px 24px 25px 0px;
        }

        &:not(:last-child) {
          border-right: 1px solid $grayE4;
        }

        & .userBlock {
          width: 100%;
          padding-bottom: 15px;
          border-bottom: 1px solid $grayE4;
        }

        & .todayBlock {
          width: 100%;

          padding-top: 15px;

          & .percentWrapper {
            display: flex;
            align-items: center;

            & .percentContainer {
              margin-left: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 16px;
              height: 16px;
              border-radius: 100px;
              background: $green;

              & svg {
                transform: rotate(180deg);
              }
            }

            & .percentContainerNegative {
              background: $red;
              & svg {
                transform: rotate(0deg);
              }
            }
            & .percentTitle {
              @include text_x_small_regular;
              font-size: 12px;
              margin-left: 4px;
              color: $green;
            }

            & .percentTitleNegative {
              color: $red;
            }
          }
        }
      }
    }

    & .tagsContainer {
      display: flex;
      & .infoBlock {
        width: 16.66%;
        padding: 25px 25px;
        // border-top: 1px solid $grayE4;
        border-bottom: 1px solid $grayE4;

        &:first-child {
          padding: 25px 25px 25px 0px;
        }

        &:not(:last-child) {
          border-right: 1px solid $grayE4;
        }

        & .userBlock {
          width: 100%;
          padding-bottom: 15px;
          border-bottom: 1px solid $grayE4;
        }

        & .todayBlock {
          width: 100%;
          padding-top: 15px;
          & .percentWrapper {
            display: flex;
            align-items: center;

            & .percentContainer {
              margin-left: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 16px;
              height: 16px;
              border-radius: 100px;
              background: $green;

              & svg {
                transform: rotate(180deg);
              }
            }

            & .percentContainerNegative {
              background: $red;
              & svg {
                transform: rotate(0deg);
              }
            }
            & .percentTitle {
              @include text_x_small_regular;
              font-size: 12px;
              margin-left: 4px;
              color: $green;
            }

            & .percentTitleNegative {
              color: $red;
            }
          }
        }
      }

      & .tagsStatistics {
        flex: 1;
        padding: 25px;
        & .title {
          @include text_large_regular;
        }

        & .tagsContainer {
          margin-top: 10px;
          display: flex;
          flex-wrap: wrap;
          gap: 30px;
        }
      }
    }
  }
}

.xSmallRegular {
  @include text_x_small_regular;
}

.largeRegular {
  @include text_large_regular;
}

.largeBold {
  @include text_large_bold;
}

.h2 {
  @include desktop_h2;
  margin-top: 9px;
}

h4 {
  @include desktop_h4;
}

.h5 {
  font-family: $primary-font;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  margin-top: 5px;
}
