@import "../../scss/main.scss";

.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $overlay;
  overflow-y: auto;
  outline: none;
}

.modal {
  height: 100%;
  & .innerContainer {
    position: relative;
    width: 900px;
    margin-top: 50px;
    border-radius: 24px;
    background: $white;
    overflow: hidden;
    padding: 30px;

    & .loaderWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20vh;
    }

    & .imagesContainer {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 10px;
      margin-top: 20px;
      & .image {
        object-fit: cover;
        width: 202px;
        height: 202px;
        background-color: $grayVeryLight;
        cursor: pointer;
      }
    }

    & .loadMoreContainer {
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
