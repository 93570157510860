@import "../../scss/main.scss";

.wrapper {
  display: flex;
  align-items: center;
  padding: 10px 0;
  position: relative;
  border-bottom: 1px solid $shadow;
  cursor: pointer;
  transition: all 0.2s linear;

  &:hover {
    background-color: $grayE430;
  }

  & .nameBlock {
    flex: 1;
    display: flex;
    align-items: center;

    & .imageWrapper {
      width: 90px;
      height: 80px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $grayE430;

      & svg {
        fill: $grayAf;
        width: 34px;
        height: 34px;
      }
    }

    & .contentContainer {
      flex: 1;
      margin-left: 15px;
      gap: 5px;
      display: flex;
      flex-direction: column;

      & .date {
        @include text_small_regular;
        color: $grayDark;
      }

      & .description {
        @include text_small_regular;
      }
    }
  }

  & .dateBlock {
    display: flex;
    flex: 0.3;
    justify-content: space-between;

    & .dotButton {
      fill: $dark;
      cursor: pointer;
    }

    & .dateItem {
      display: flex;
      align-items: center;
      gap: 5px;
      & svg {
        fill: $grayLight;
      }

      & span {
        @include text_small_bold;
        color: $grayLight;
      }
    }
  }
}

.menuWrapper {
  position: absolute;
  right: -20px;
  top: 65px;
  width: 135px;
  box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.14);
  border-radius: 15px;
  background: #fff;
  z-index: 99;
  padding: 10px;

  & .menuButton {
    display: flex;
    align-items: center;
    gap: 3px;
    padding: 6px 8px;
    border-radius: 10px;
    transition: all linear 0.2s;
    cursor: pointer;

    & .editIcon {
      fill: $dark;
    }

    & .deleteIcon {
      fill: $red;
    }

    & .deleteLoader {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      background: #f8f8f8;
    }

    & span {
      @include text_small_bold;
      margin-left: 10px;
      color: $dark;
    }
  }
}
