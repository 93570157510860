@import "../../scss/main.scss";

.wrapper {
  position: absolute;
  box-shadow: 0px 18px 50px 0px #00000024;
  width: 550px;
  border-radius: 15px;
  top: -10px;
  left: -2px;
  background-color: $white;
  padding: 10px;
  z-index: 99;

  & .searchWrapper {
    border-radius: 16px;
    border: 3px solid $additional;
    height: 56px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    & .searchIcon {
      fill: $dark;
      stroke: transparent;
    }
    & .input {
      height: 100%;
      border: none;
      flex: 1;
      @include text_small_bold;
      outline: none;

      &::placeholder {
        color: $grayLight;
      }
    }
  }

  & .listContainer {
    margin-top: 15px;
    gap: 9px;
    display: flex;
    flex-direction: column;
    max-height: 353px;

    overflow-y: scroll;

    & .loaderWrapper {
      height: 353px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & .paginationLoaderWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 15px;
      margin-bottom: 15px;
    }

    &::-webkit-scrollbar-track {
    }

    &::-webkit-scrollbar {
      background-color: $white;
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $grayE4;
      border-radius: 100px;
    }
    & .listItem {
      padding: 6px 8px;
      border-radius: 6px;
      transition: all 0.2s linear;

      & .listItemTitle {
        @include text_small_regular;
        color: $black;

        & .brand {
          color: $grayLight;
        }
      }

      &:hover {
        background-color: $grayE430;
      }
    }
  }
}
