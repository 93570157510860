@import "../../scss/main.scss";

.wrapper {
  display: flex;
  align-items: center;
  & .infoContainer {
    display: flex;
    flex: 1.2;
    gap: 10px;

    & .nameInput {
      flex: 1;
    }

    & .amountInput {
      flex: 0.5;
    }
    & .unitInput {
      flex: 1;
    }
  }

  & .nameContainer {
    height: 56px;
    background-color: $grayE430;
    border-radius: 8px;
    padding: 0 18px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    caret-color: $primary;
    color: #000;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    @include text_small_bold;

    & .name {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    & .brand {
      display: block;
      color: $grayDark;
    }
  }

  & .sectionDivider {
    height: 30px;
    width: 1px;
    background: $grayE4;
    margin: 0 10px;
  }

  & .macroContainer {
    display: flex;
    gap: 10px;
    flex: 1;

    & .calories {
      flex: 1.2;
      @include text_small_bold;
    }

    & .macroItem {
      flex: 1;
      @include text_small_bold;
    }
  }

  & .deleteButton {
    cursor: pointer;
    width: 26px;
    height: 26px;
  }
}
