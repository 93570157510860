@import "../../../../../scss/main.scss";

.wrapper {
  & .sectionHeader {
    display: flex;
    align-items: center;
    padding-bottom: 17px;
    border-bottom: 1px solid $shadow;
    margin-top: 29px;

    & .nameBlock {
      flex: 1;
      display: flex;
      align-items: center;
      @include text_x_small_bold;
      color: $deactive;
      gap: 3px;
      cursor: pointer;
      transition: all 0.2s linear;
      & svg {
        stroke: $deactive;
      }
    }

    & .weekBlock {
      flex: 0.4;
      display: flex;
      align-items: center;
      @include text_x_small_bold;
      color: $deactive;
      gap: 3px;

      & svg {
        stroke: $deactive;
      }
    }
    & .memberBlock {
      flex: 0.4;
      display: flex;
      align-items: center;
      @include text_x_small_bold;
      color: $deactive;
      gap: 3px;

      & svg {
        stroke: $deactive;
      }
    }

    & .dateBlock {
      flex: 0.4;
      display: flex;
      align-items: center;
      @include text_x_small_bold;
      color: $deactive;
      gap: 3px;

      & svg {
        stroke: $deactive;
      }
    }

    & .activeSortValue {
      color: $black;

      & svg {
        stroke: $black;
      }
    }

    & .sectionHeaderArrow {
      transition: all 0.2s linear;
    }

    & .sectionHeaderArrowUp {
      transform: rotate(180deg);
    }
  }
}
