@import "../../scss/main.scss";

.wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;

  & .iconWrapper {
    width: 28px;
    height: 28px;
    border-radius: 8px;
    background: #d9e0e9;
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
      fill: $grayDark;
      width: 17.5px;
      height: 17.5px;
    }
  }

  & .contentContainer {
    flex: 1;
    & .title {
      @include text_small_bold;

      & .unit {
        color: $grayDark;
      }

      & .brandName {
        @include text_small_regular;
        color: $textMain;
      }
    }

    & .macroContainer {
      width: 100%;
      display: flex;
      align-items: center;

      & .macroItem {
        display: flex;
        flex-direction: column;
        flex: 1;
        & .macroValue {
          @include text_x_small_regular;
        }

        & .macroTitle {
          @include text_x_small_regular;
          color: $grayDark;
        }
      }
    }
  }
}
