@import "../../../scss/main.scss";

.wrapper {
  max-width: 1520px;
  padding-bottom: 30px;
  & .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .title {
      @include desktop_h4;
    }

    & .headerAddButton {
      width: 230px;
    }
  }

  & .contentContainer {
    background: $white;
    border-radius: 24px;
    margin-top: 30px;
    padding: 30px;

    -webkit-box-shadow: 0px 0px 30px 0px $shadow;
    -moz-box-shadow: 0px 0px 30px 0px $shadow;
    box-shadow: 0px 0px 30px 0px $shadow;
    & .searchContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    & .mainLoader {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 70vh;
    }

    & .sectionHeader {
      display: flex;
      align-items: center;
      padding-bottom: 17px;
      border-bottom: 1px solid $shadow;
      margin-top: 29px;

      & .nameBlock {
        flex: 1;
        display: flex;
        align-items: center;
        @include text_x_small_bold;
        color: $deactive;
        margin-left: 24px;
        gap: 3px;

        & svg {
          stroke: $deactive;
        }
      }

      & .categoryBlock {
        flex: 0.8;
        display: flex;
        align-items: center;
        @include text_x_small_bold;
        color: $deactive;
        gap: 3px;

        & svg {
          stroke: $deactive;
        }
      }

      & .dateBlock {
        flex: 0.4;
        display: flex;
        align-items: center;
        @include text_x_small_bold;
        gap: 3px;
        cursor: pointer;

        & svg {
          transition: all 0.2s linear;
          stroke: $dark;
        }
      }
      & .dateBlockActive {
        & svg {
          transition: all 0.2s linear;
          transform: rotate(180deg);
        }
      }
    }
    & .listContainer {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-top: 28px;
      & .loaderWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50vh;
      }
    }
  }
}
