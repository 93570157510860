@import "../../scss/main.scss";

.wrapper {
  margin-top: 15px;
  & .commentHeader {
    display: flex;
    flex-direction: row;

    & .imageWrapper {
      width: 28px;
      height: 28px;
      background: $grayE430;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;

      & svg {
        fill: $grayAf;
        width: 18px;
        height: 18px;
      }
    }

    & .title {
      @include text_small_bold;
      margin-left: 10px;
    }

    & .online {
      @include text_x_small_regular;
      line-height: 22px;
      color: $grayDark;
      margin-left: 8px;
    }
  }

  & .commentContainer {
    padding: 10px 15px;
    background-color: $grayVeryLight;
    border-radius: 10px;
    margin-left: 38px;
    margin-top: -2px;

    & p {
      @include text_small_regular;
    }
  }

  & .footer {
    margin-top: 5px;
    align-items: center;
    display: flex;
    gap: 10px;

    margin-left: 38px;

    & .footerItem {
      display: flex;
      gap: 5px;
      align-items: center;
      & svg {
        fill: $black;
        width: 16px;
        height: 16px;
      }
      & span {
        @include text-small_regular;
      }
    }

    & span {
      @include text-small_regular;
    }
  }
}
