@import "../../scss/main.scss";

.wrapper {
  flex: 1;
  & .title {
    @include text_x_large_bold;
  }

  & .rangeSwitcher {
    height: 36px;
    border-radius: 47px;
    background-color: $grayVeryLight;
    display: flex;
    padding: 4px;
    position: relative;
    margin-top: 20px;

    & .rangeButton {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @include text_small_bold;
      z-index: 10;
    }

    & .rangeIndicator {
      border-radius: 50px;
      background-color: $primary;
      height: 28px;
      position: absolute;
      z-index: 0;
      transform: all 0.2s linear;
    }

    & .rangeButtonActive {
      color: $white;
    }
  }
}
