@import "../../scss/main.scss";

.wrapper {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid $shadow;
  transition: all 0.2s linear;
  cursor: pointer;

  &:hover {
    background: $grayE430;
  }

  & .nameBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    gap: 10px;

    & .imageWrapper {
      width: 44px;
      height: 44px;
      border-radius: 10px;

      background-color: $grayE430;

      display: flex;
      align-items: center;
      justify-content: center;

      & svg {
        fill: $grayAf;
        width: 20px;
        height: 20px;
      }
    }

    & .title {
      @include text_medium_bold;
    }
  }

  & .weekBlock {
    flex: 0.4;
    @include text_small_regular;
  }

  & .memberBlock {
    flex: 0.4;
    @include text_small_regular;

    & .membersContainer {
      display: flex;
      align-items: center;
      margin-left: 4%;
      & .avatar {
        width: 28px;
        height: 28px;
        border-radius: 100px;
        border: 3px solid $white;
        background-color: $grayE4;
        margin-left: -7%;
        display: flex;
        align-items: center;
        justify-content: center;

        & svg {
          fill: $grayAf;
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  & .dateBlock {
    display: flex;
    justify-content: space-between;
    flex: 0.4;

    & .date {
      @include text_small_regular;
    }

    & .arrowWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 100px;
      background: $primary;

      & svg {
        transform: rotate(-90deg);
      }
    }
  }
}
