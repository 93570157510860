@import "../../scss/main.scss";
.checkedWrapper {
  padding: 8px 10px;
  display: flex;
  align-items: center;
  gap: 7px;
  border-radius: 8px;
  background-color: $primary;
}
.uncheckedWrapper {
  padding: 8px 10px;
  display: flex;
  align-items: center;
  gap: 7px;
  border-radius: 8px;
  background-color: #dfd4ff;
}

.uncheckedTitle {
  @include text_x_small_bold;
  color: $primary;
}
.checkedTitle {
  @include text_x_small_bold;
  color: $white;
}

.checked {
  display: flex;
  width: 16px;
  height: 16px;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & svg {
    fill: $white;
  }
}

.unchecked {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background-color: $white;
  cursor: pointer;
}

.inputWrapper {
  min-width: 60px;
  padding: 0 5px;
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  margin-left: 15px;
  .gramsInput {
    border: none;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    outline: none;
    background: #fff;
    width: 50px;
    text-align: center;
    @include text_x_small_bold;
  }

  .grams {
    @include text_x_small_bold;
  }
}
