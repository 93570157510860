@import "../../scss/main.scss";

.wrapper {
  & .selectorWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    & .selectorContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 56px;
      background-color: $grayE430;
      border-radius: 8px;
      padding: 15px 18px;
      transition: all 0.2s linear;

      &:hover {
        background-color: $grayE4;
        cursor: pointer;
      }

      & .unitName {
        @include text_medium_bold;
        max-width: 220px;
        overflow: hidden;

        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      & .macroContainer {
        display: flex;
        flex-direction: row;
        gap: 30px;
        flex: 1;
        margin: 0 20px;

        & .macroItem {
          display: flex;
          flex-direction: column;

          & .macroValue {
            @include text_small_bold;
          }

          & .macroTitle {
            @include text_x_small_regular;
            color: $grayDark;
          }
        }
      }

      & .arrowClose {
        stroke: #000;
        transform: rotate(180deg);
        transition: all 0.2s linear;
      }

      & .arrowOpen {
        stroke: #000;
        transform: rotate(0deg);
      }
    }
  }

  & .editorContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;

    & .defaultContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      & span {
        @include text_small_regular;
      }
    }
  }

  & .inputsContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
}
