@import "../../scss/main.scss";

.wrapperCompleted {
  border: 3px solid $green !important;

  & .contentContainer {
    background: $greenOpacity10;
  }

  & .macroContainer {
    background: $greenOpacity20 !important;
  }
}

.wrapper {
  border: 3px solid #f5f7fa;
  border-radius: 15px;
  position: relative;
  & .contentContainer {
    padding: 20px;

    & .title {
      @include text_large_bold;
    }
  }

  & .skipContainer {
    display: flex;
    align-items: center;
    gap: 15px;

    & .skipTitle {
      @include text_medium_medium;
    }
  }
  & .checkIconWrapper {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $green;
    border-radius: 100px;
    position: absolute;
    right: 10px;
    top: 10px;

    & svg {
      width: 16px;
      height: 16px;
    }
  }

  & .recipesContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
  }

  & .macroContainer {
    background: $grayVeryLight;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & .macroDivider {
      width: 1px;
      height: 24px;
      background: $grayMedium;
    }

    & .macroItem {
      display: flex;
      flex-direction: column;
      align-items: center;

      & .macroItemValue {
        @include text_small_bold;
      }

      & .macroItemTitle {
        @include text_x_small_regular;
        color: $grayDark;
      }
    }
  }
}
